import React from 'react';

import { Info, Check, Alert } from './icons';

export default function StaticAlert({
  children,
  error,
  success,
  info,
  style,
  ...other
}) {
  let colourStyles = {};
  let textStyles = {};
  if (error) {
    colourStyles = {
      backgroundColor: '#f8d7da',
      borderColor: '#f5c6cb',
    };
    textStyles = {
      color: '#721c24',
    };
  }
  if (success) {
    colourStyles = {
      backgroundColor: '#d4edda',
      borderColor: '#c3e6cb',
    };
    textStyles = {
      color: '#155724',
    };
  }
  if (info) {
    colourStyles = {
      backgroundColor: '#CCE5FF',
      borderColor: '#B8DAFF',
    };
    textStyles = {
      color: '#004085',
    };
  }
  const alertContent =
    typeof children !== 'object'
      ? { dangerouslySetInnerHTML: { __html: children } }
      : { children };
  return (
    <div
      style={{
        ...alertStyle,
        ...colourStyles,
        ...style,
      }}
      {...other}
    >
      {info && <Info />}
      {error && <Alert />}
      {success && <Check />}
      <div
        {...alertContent}
        style={{
          fontSize: `0.9375rem`,
          flex: 1,
          ...textStyles,
        }}
      />
    </div>
  );
}

const alertStyle = {
  padding: `0.875rem 1.875rem`,
  borderStyle: 'solid',
  borderWidth: `1px`,
  borderRadius: `4px`,
  display: `flex`,
};

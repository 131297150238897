import React from 'react';

const width = `16px`;
const height = `16px`;

const iconStyles = {
  display: `inline-block`,
  width,
  height,
  flex: `0 0 ${width}`,
  lineHeight: 0,
  marginRight: `30px`,
  marginTop: `9px`,
};

export const Info = () => {
  return (
    <div style={iconStyles}>
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C15.9952 3.5837 12.4163 0.00477657 8 0ZM8.16667 3.33333C8.71895 3.33333 9.16667 3.78105 9.16667 4.33333C9.16667 4.88562 8.71895 5.33333 8.16667 5.33333C7.61438 5.33333 7.16667 4.88562 7.16667 4.33333C7.16667 3.78105 7.61438 3.33333 8.16667 3.33333ZM7 12.3333H9.66667C10.0349 12.3333 10.3333 12.0349 10.3333 11.6667C10.3333 11.2985 10.0349 11 9.66667 11H9.16667C9.07462 11 9 10.9254 9 10.8333V7.66667C9 6.93029 8.40305 6.33333 7.66667 6.33333H7C6.63181 6.33333 6.33333 6.63181 6.33333 7C6.33333 7.36819 6.63181 7.66667 7 7.66667H7.5C7.59205 7.66667 7.66667 7.74129 7.66667 7.83333V10.8333C7.66667 10.9254 7.59205 11 7.5 11H7C6.63181 11 6.33333 11.2985 6.33333 11.6667C6.33333 12.0349 6.63181 12.3333 7 12.3333Z"
          fill="#004085"
        />
      </svg>
    </div>
  );
};

export const Check = () => {
  return (
    <div style={iconStyles}>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none">
        <path
          d="M13.9114 1.82701L12.1804 0.0910721C12.1222 0.0327666 12.0433 0 11.9609 0C11.8786 0 11.7996 0.0327666 11.7415 0.0910721L4.43063 7.40684C4.37247 7.46515 4.29351 7.49792 4.21115 7.49792C4.12882 7.49792 4.04984 7.46515 3.99168 7.40684L2.26069 5.6709C2.20255 5.61259 2.12357 5.57984 2.04121 5.57984C1.95887 5.57984 1.8799 5.61259 1.82174 5.6709L0.0907615 7.40684C-0.0302538 7.52791 -0.0302538 7.72413 0.0907615 7.84518L3.99168 11.7467C4.04984 11.805 4.12882 11.8378 4.21115 11.8378C4.29351 11.8378 4.37247 11.805 4.43063 11.7467L13.9114 2.26101C14.0295 2.14047 14.0295 1.94755 13.9114 1.82701Z"
          fill="#155724"
        />
      </svg>
    </div>
  );
};

export const Alert = () => {
  return (
    <div style={iconStyles}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.119 20L13.772 2.15002C13.4267 1.49121 12.7443 1.07837 12.0005 1.07837C11.2567 1.07837 10.5743 1.49121 10.229 2.15002L0.880997 20C0.556229 20.6201 0.579224 21.3647 0.941605 21.9635C1.304 22.5624 1.95306 22.9281 2.653 22.9281H21.347C22.0469 22.9281 22.696 22.5624 23.0584 21.9635C23.4208 21.3647 23.4438 20.6201 23.119 20ZM11 8.4231C11 7.87085 11.4477 7.4231 12 7.4231C12.5523 7.4231 13 7.87085 13 8.4231V14.4231C13 14.9753 12.5523 15.4231 12 15.4231C11.4477 15.4231 11 14.9753 11 14.4231V8.4231ZM12.022 19.933H12.05C12.4471 19.9265 12.8248 19.7603 13.0977 19.4718C13.3706 19.1832 13.5157 18.7969 13.5 18.4C13.4678 17.5813 12.7953 16.934 11.976 16.933H11.948C11.5519 16.9402 11.1753 17.1064 10.903 17.3942C10.6306 17.6819 10.4854 18.067 10.5 18.463C10.5311 19.2819 11.2026 19.9303 12.022 19.933Z"
          fill="#721c24"
        />
      </svg>
    </div>
  );
};
